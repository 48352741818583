import { Container, GlobalStyles, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import internetCommonConsultApiService from '../api/internet-consult';
import { handleApiV2 } from '../common/http_util';
import { useLoading } from '../context/LoadingContext';
import {
  GetInternetIptvBundlePlanConsultResponse,
  GetInternetPlanConsultResponse,
  isGetInternetPlanConsultResponseType,
} from '../entity/internet-plan/response/internet-plan-consult-response';
import pbl from '../pbl/pbl-service/pbl-service';
import InternetConsultHistoryCreateSection from '../components/OrderInternetDetailPage/InternetConsultHistoryCreateSection';
import InternetConsultDetailHeader from '../components/InternetConsult/InternetConsultDetail/InternetConsultDetailHeader';
import InternetConsultDetailHistory from '../components/InternetConsult/InternetConsultDetail/InternetConsultDetailHistory';
import InternetConsultDetailInfo from '../components/InternetConsult/InternetConsultDetail/InternetConsultDetailInfo';

const globalStyles = (
  <GlobalStyles
    styles={{
      td: {
        ':nth-of-type(odd)': {
          backgroundColor: '#eeeeee',
          fontWeight: 600,
        },
      },
    }}
  />
);

interface Props {
  iptvBundle?: boolean;
}

const InternetConsultDetailPage: FC<Props> = ({ iptvBundle = false }) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { consultId: _consultId } = useParams();
  const consultId = _consultId ? Number(_consultId) : undefined;
  const [internetConsult, setInternetConsult] = useState<
    GetInternetIptvBundlePlanConsultResponse | GetInternetPlanConsultResponse
  >();

  useEffect(() => {
    if (!consultId) return;
    pbl('pageview', 'none', 'internet_fixed_offer_detail', {
      object: {
        id: consultId.toString(),
      },
      eventProperties: {
        iptvBundle,
        consultId,
      },
    });
  }, [consultId]);

  useEffect(() => {
    fetchOrder();
  }, [consultId]);

  const fetchOrder = async () => {
    setLoading(true);

    if (!consultId) return;

    try {
      const response = await handleApiV2<
        | GetInternetPlanConsultResponse
        | GetInternetIptvBundlePlanConsultResponse
      >(
        () =>
          internetCommonConsultApiService
            .iptvBundle(iptvBundle)
            .getConsult(consultId),
        navigate,
      );

      setInternetConsult(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const afterHistorySubmitProcess = () => {
    fetchOrder();

    if (window.opener) {
      window.opener.location.reload();
    }
  };

  return (
    <>
      {globalStyles}
      <Container
        sx={{
          width: {
            xs: '100%',
            lg: '75%',
          },
          paddingBottom: '64px',
        }}
      >
        <InternetConsultDetailHeader />
        {internetConsult && (
          <InternetConsultDetailInfo
            name={internetConsult.applicantName}
            createdAt={internetConsult.openedAt}
            phoneNumber={internetConsult.applicantPhoneNumber}
            planName={
              isGetInternetPlanConsultResponseType(internetConsult)
                ? internetConsult.internetPlanName
                : internetConsult.internetIptvBundlePlanName
            }
            status={internetConsult.status}
          />
        )}
        {consultId && (
          <InternetConsultHistoryCreateSection
            iptvBundle={iptvBundle}
            consultId={consultId}
            afterSubmitProcess={afterHistorySubmitProcess}
          />
        )}
        {internetConsult && (
          <InternetConsultDetailHistory list={internetConsult.histories} />
        )}
      </Container>
    </>
  );
};

export default InternetConsultDetailPage;
