import { useEffect } from 'react';
import pbl from '../pbl/pbl-service/pbl-service';
import { partnersAdminUserControllerSerivce } from '../new-api/partners-admin-user-controller-service';
import CommonAccountManagePage from '../components/AccountManagePage/CommonAccountManagePage';
import { usePermission } from '../router/usePermission';

const AdminAccountManagePage = () => {
  const { isPartnersManagePermission } = usePermission();

  useEffect(() => {
    pbl('pageview', 'none', 'admin_account_manage');
  }, []);

  return (
    <CommonAccountManagePage
      getAllMembers={() => {
        return partnersAdminUserControllerSerivce.getAdminUsersForManageAdmin();
      }}
      registerUser={(data) => {
        return partnersAdminUserControllerSerivce.registerAdminUser({
          body: {
            name: data.name,
            password: data.password,
            userId: data.userId,
            partnerCompanyId: data.partnerCompanyId,
          },
        });
      }}
      hasPermission={isPartnersManagePermission}
      title="어드민 계정 관리"
    />
  );
};

export default AdminAccountManagePage;
