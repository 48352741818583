import {
  handleResponseInterceptorError,
  requestInterceptorWithAuth,
} from '../../common/http_util';
import { type AbstractOasApiController } from './abstract-oas-api-controller';
import { createClient, type OpenApiClientOptions } from './create-client';
import { instanceApiAdapter } from './openapi-instance-api-adapter';

export function createBrowserInstance<T>(
  ClassType: new (
    ...params: ConstructorParameters<typeof AbstractOasApiController>
  ) => T,
  options?: OpenApiClientOptions,
): T {
  const client = createClient(options);
  client.interceptors.request.use(requestInterceptorWithAuth);
  client.interceptors.response.use(null, handleResponseInterceptorError);

  return new ClassType((path, method, params, data) => {
    return instanceApiAdapter(client, path, method, params, data);
  });
}
