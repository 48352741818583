import { Box, Button, Divider, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { FC, useEffect, useState } from 'react';
import { authApiService, UserMe } from '../api/auth';
import { useLoading } from '../context/LoadingContext';
import AuthSettingsModal from '../components/AuthSettingsPage/AuthSettingsModal';
import { permissionTypeRenderCell } from '../utils/gridRenderer';
import pbl from '../pbl/pbl-service/pbl-service';
import { useRole } from '../router/useRole';

const columns: GridColDef[] = [
  { field: 'name', headerName: '이름', width: 240 },
  {
    field: 'order_excel',
    headerName: '엑셀',
    width: 80,
    align: 'center',
    renderCell: permissionTypeRenderCell('PARTNERS_EXCEL_UPLOAD_DOWNLOAD'),
  },
  {
    field: 'order_change',
    headerName: '접수 내용 수정',
    width: 100,
    align: 'center',
    renderCell: permissionTypeRenderCell('PARTNERS_ORDER_CHANGE'),
  },
  {
    field: 'order_register',
    headerName: '접수 내용 조회',
    width: 100,
    align: 'center',
    renderCell: permissionTypeRenderCell('PARTNERS_ORDER_REGISTER'),
  },
  {
    field: 'internet_order',
    headerName: '인터넷',
    width: 80,
    align: 'center',
    renderCell: permissionTypeRenderCell('PARTNERS_INTERNET_ORDER'),
  },
  {
    field: 'plan_manage',
    headerName: '요금제 관리',
    width: 100,
    align: 'center',
    renderCell: permissionTypeRenderCell('PARTNERS_MOBILE_PLAN_ORDER_MANAGE'),
  },
  {
    field: 'admin_manage',
    headerName: '파트너사 관리',
    width: 100,
    align: 'center',
    renderCell: permissionTypeRenderCell('PARTNERS_MANAGE_ADMIN'),
  },
];

const AuthSettingsPage: FC = () => {
  const { isAdminAccount } = useRole();
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [selectedUsers, setSelectedUsers] = useState<UserMe[]>([]);
  const [openAuthSettingsModal, setOpenAuthSettingsModal] =
    useState<boolean>(false);
  const [members, setMembers] = useState<UserMe[]>([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    pbl('pageview', 'none', 'authority');
  }, []);

  useEffect(() => {
    loadMembers();
  }, []);

  const loadMembers = async () => {
    setLoading(true);
    try {
      const rs = await authApiService.getMembers();
      setMembers(rs.data.result?.members || []);
    } finally {
      setLoading(false);
    }
  };

  if (!isAdminAccount) {
    return <div>잘못된 접근입니다.</div>;
  }

  const onOpenAuthSettingsModalHandler = () => {
    setOpenAuthSettingsModal(true);
    setSelectedUsers(
      members.filter((member) =>
        selectedIds.find((selectedId) => selectedId === member.id),
      ),
    );
  };

  const onCloseAuthSettingsModalHandler = () => {
    setOpenAuthSettingsModal(false);
  };

  const onDidChangeAuthSettingsHandler = () => {
    loadMembers();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ minWidth: '640px', margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" my={2}>
            권한 설정
          </Typography>
          <Button
            variant="outlined"
            disabled={selectedIds.length === 0}
            onClick={onOpenAuthSettingsModalHandler}
          >
            권한 변경
            {selectedIds.length > 0 ? ` (${selectedIds.length}명)` : undefined}
          </Button>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          paginationMode="client"
          autoHeight
          pagination
          pageSizeOptions={[5, 10, 30]}
          rows={members}
          columns={columns}
          checkboxSelection
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
          onRowSelectionModelChange={setSelectedIds}
        />
      </div>
      <AuthSettingsModal
        open={openAuthSettingsModal}
        onClose={onCloseAuthSettingsModalHandler}
        members={selectedUsers}
        onDidChange={onDidChangeAuthSettingsHandler}
      />
    </div>
  );
};

export default AuthSettingsPage;
