import {
  Box,
  Container,
  Divider,
  GlobalStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { orderProgressApiService } from '../api/order-progress';
import { handleApi } from '../common/http_util';
import {
  convertStringToCertType,
  convertStringToLicenseType,
  convertStringToMno,
  convertStringToPaymentMethod,
  convertStringToPaymentNotiChannel,
  convertStringToRegisterType,
} from '../common/order-util';
import { comma } from '../common/string_util';
import { useLoading } from '../context/LoadingContext';
import { OrderProgressDetail } from '../entity/order-progress';
import pbl from '../pbl/pbl-service/pbl-service';
import { VerticalColorTable } from '../shared/components/coloredTable';
import { dateUtils } from '../utils';

const globalStyles = (
  <GlobalStyles
    styles={{
      td: {
        ':nth-of-type(odd)': {
          backgroundColor: '#eeeeee',
          fontWeight: 600,
        },
      },
    }}
  />
);

const colGroupDefinition = (
  <colgroup>
    <col style={{ width: '15%' }} />
    <col style={{ width: '35%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '35%' }} />
  </colgroup>
);

export default function OrderProgressDetailPage() {
  const { progressId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const [progress, setProgress] = useState<OrderProgressDetail>();

  useEffect(() => {
    if (!progressId) return;
    pbl('pageview', 'none', 'progress_detail', {
      object: {
        id: progressId,
      },
      eventProperties: {
        progressId,
      },
    });
  }, [progressId]);

  const fetchProgress = async () => {
    setLoading(true);

    if (!progressId) return;

    try {
      const progress = await handleApi(
        () => orderProgressApiService.get(progressId),
        navigate,
        (error) => {
          alert(error);
        },
      );

      if (!progress) return;
      setProgress(progress);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgress();
  }, [progressId]);

  if (!progress) return null;

  return (
    <>
      {globalStyles}
      <Container
        sx={{
          width: {
            xs: '100%',
            lg: '75%',
          },
          paddingBottom: '64px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" my={3}>
            셀프개통 진행사항
          </Typography>
        </Box>
        <Divider />

        <Typography variant="h6" my={2}>
          접수 정보
        </Typography>
        <TableContainer component={Paper} elevation={5}>
          <VerticalColorTable>
            {colGroupDefinition}
            <TableBody>
              <TableRow>
                <TableCell>신청자 이름</TableCell>
                <TableCell>{progress?.customerName}</TableCell>
                <TableCell>가입 유형</TableCell>
                <TableCell>
                  {convertStringToRegisterType(progress?.registerType)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>신청 일자</TableCell>
                <TableCell>
                  {dateUtils.dateToLocaleString(progress?.createdAt)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>개통 번호</TableCell>
                <TableCell colSpan={3}>{progress?.orderPhoneNumber}</TableCell>
              </TableRow>
            </TableBody>
          </VerticalColorTable>
        </TableContainer>

        <Box
          sx={{
            my: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">요금제 정보</Typography>
        </Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TableContainer component={Paper} elevation={5}>
            <VerticalColorTable>
              <TableBody>
                <TableRow>
                  <TableCell>요금제 이름</TableCell>
                  <TableCell>{progress?.planName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>통신사</TableCell>
                  <TableCell>{progress?.mvno}</TableCell>
                </TableRow>
              </TableBody>
            </VerticalColorTable>
          </TableContainer>
        </Box>

        <>
          <Typography variant="h6" my={2}>
            고객 정보
          </Typography>
          <TableContainer component={Paper} elevation={5}>
            <VerticalColorTable>
              {colGroupDefinition}
              <TableBody>
                <TableRow>
                  <TableCell>신청자 이름</TableCell>
                  <TableCell>{progress?.customerName}</TableCell>
                  <TableCell>가입 유형</TableCell>
                  <TableCell>
                    {convertStringToRegisterType(progress?.registerType)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>현재 통신사</TableCell>
                  <TableCell>
                    {progress.phoneMvno
                      ? `${progress.phoneMvno} (${convertStringToMno(
                          progress.phoneMno,
                        )})`
                      : convertStringToMno(progress.phoneMno)}
                  </TableCell>
                  <TableCell>전화번호</TableCell>
                  <TableCell>
                    {progress?.customerPhoneNumber?.replace(
                      /(\d{3})(\d{4})(\d{4})/,
                      '$1-$2-$3',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>주민등록번호</TableCell>
                  <TableCell>
                    {`${progress.registrationNumberFirst}-${progress.registrationNumberSecond}`}
                  </TableCell>
                  <TableCell>본인 인증 방법</TableCell>
                  <TableCell>
                    {convertStringToCertType(progress.certificationType)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>실명확인자료</TableCell>
                  <TableCell>
                    {convertStringToLicenseType(progress.licenseType)}
                  </TableCell>
                  <TableCell>신분증 정보</TableCell>
                  <TableCell>
                    {progress.licenseType === 'registration'
                      ? `발급일자: ${progress.licenseIssuedAt}`
                      : `면허 번호: ${progress.driverLicense1}-${progress.driverLicense2}-${progress.driverLicense3}-${progress.driverLicense4} | 발급일자: ${progress.licenseIssuedAt}`}
                  </TableCell>
                </TableRow>
                {progress.registerType === 'change' && (
                  <>
                    <TableRow>
                      <TableCell>번호이동 인증 수단</TableCell>
                      <TableCell>{progress.changeCertBillingMethod}</TableCell>
                      <TableCell>번호이동 인증 정보</TableCell>
                      <TableCell>{progress.changeCertBillingInfo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>번호이동 인증 수단 2</TableCell>
                      <TableCell>
                        {progress.changeCertBillingMethodSub}
                      </TableCell>
                      <TableCell>번호이동 인증 정보 2</TableCell>
                      <TableCell>{progress.changeCertBillingInfoSub}</TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell>유심 보유 여부</TableCell>
                  <TableCell>
                    {progress.hasUsim === true
                      ? '유심 보유 중'
                      : '유심 미보유(택배 배송)'}
                  </TableCell>
                  {progress.hasUsim !== true && (
                    <>
                      <TableCell>유심 종류</TableCell>
                      <TableCell>
                        {progress.isNfcUsim === true ? 'NFC 유심' : '일반 유심'}
                      </TableCell>
                    </>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>유심 모델명</TableCell>
                  <TableCell>{progress.usimModel}</TableCell>
                  <TableCell>유심 일련번호</TableCell>
                  <TableCell>{progress.usimSerialNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>택배사</TableCell>
                  <TableCell>{progress.deliveryCompany}</TableCell>
                  <TableCell>송장 번호</TableCell>
                  <TableCell>{progress.deliveryTrackingNumber}</TableCell>
                </TableRow>
                {progress.registerType === 'new' && (
                  <TableRow>
                    <TableCell>희망번호 1</TableCell>
                    <TableCell>{progress.wishNumber1}</TableCell>
                    <TableCell>희망번호 2</TableCell>
                    <TableCell>{progress.wishNumber2}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </VerticalColorTable>
          </TableContainer>
        </>

        <>
          <Typography variant="h6" my={2}>
            요금 납부 정보
          </Typography>
          <TableContainer component={Paper} elevation={5}>
            <VerticalColorTable>
              {colGroupDefinition}
              <TableBody>
                <TableRow>
                  <TableCell>수령인 이름</TableCell>
                  <TableCell>{progress.recipientName}</TableCell>
                  <TableCell>수령인 연락처</TableCell>
                  <TableCell>
                    {progress.recipientPhoneNumber?.replace(
                      /(\d{3})(\d{4})(\d{4})/,
                      '$1-$2-$3',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>이메일</TableCell>
                  <TableCell>{progress.email}</TableCell>
                  <TableCell>주소</TableCell>
                  <TableCell>
                    {`(${progress.postalCode}) ${progress.addressLevel1} ${progress.addressLevel2}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>청구서</TableCell>
                  <TableCell>
                    {convertStringToPaymentNotiChannel(
                      progress.paymentNotiChannel,
                    )}
                  </TableCell>
                  <TableCell>자동 납부 방법</TableCell>
                  <TableCell>
                    {convertStringToPaymentMethod(progress.paymentMethod)}
                  </TableCell>
                </TableRow>
                {progress.paymentMethod === 'account' && (
                  <TableRow>
                    <TableCell>은행</TableCell>
                    <TableCell>{progress.bank}</TableCell>
                    <TableCell>계좌번호</TableCell>
                    <TableCell>{progress.accountNumber}</TableCell>
                  </TableRow>
                )}
                {progress.paymentMethod === 'card' && (
                  <TableRow>
                    <TableCell>카드번호</TableCell>
                    <TableCell>
                      {progress.cardCompany} {progress.cardNumber}
                    </TableCell>
                    <TableCell>유효기간</TableCell>
                    <TableCell>{`${progress.cardExpiresMonth}/${progress.cardExpiresYear}`}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>위약금</TableCell>
                  <TableCell>{comma(progress.penaltyFee)}원</TableCell>
                  <TableCell>잔여 할부금</TableCell>
                  <TableCell>{comma(progress.installmentFee)}원</TableCell>
                </TableRow>
              </TableBody>
            </VerticalColorTable>
          </TableContainer>
        </>
      </Container>
    </>
  );
}
