import { AccountCreatingForm } from '../components/Accounts/CreatingForm';
import { useEffect } from 'react';
import pbl from '../pbl/pbl-service/pbl-service';

const AccountSettingsPage = () => {
  useEffect(() => {
    pbl('pageview', 'none', 'account_create');
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
      }}
    >
      <AccountCreatingForm />
    </div>
  );
};

export default AccountSettingsPage;
