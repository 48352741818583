import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/UserContext';

export function usePermission() {
  const { permissions, roles } = useAuth();
  const navigate = useNavigate();

  const isOrderPermission = permissions.includes('PARTNERS_ORDER_REGISTER');
  const isEditPermission = permissions.includes('PARTNERS_ORDER_CHANGE');
  const isExcelPermission = permissions.includes(
    'PARTNERS_EXCEL_UPLOAD_DOWNLOAD',
  );
  const isInternetPermission = permissions.includes('PARTNERS_INTERNET_ORDER');
  const isPlanPermission = permissions.includes(
    'PARTNERS_MOBILE_PLAN_ORDER_MANAGE',
  );
  const isPartnersManagePermission = permissions.includes(
    'PARTNERS_MANAGE_ADMIN',
  );

  // TODO: 여기 권한 추가로 체크하기
  const isAuthorityEnableDownloadMoyoProcessingOrder =
    roles.includes('ROLE_ADMIN') &&
    permissions.includes('PARTNERS_EXCEL_UPLOAD_DOWNLOAD');

  const fallbackPath = isOrderPermission
    ? '/orders/register'
    : isInternetPermission
    ? '/orders/internet'
    : isPlanPermission
    ? '/plans'
    : '/';

  const goToFallbackPath = () => {
    navigate(fallbackPath);
  };

  return {
    isEditPermission,
    isExcelPermission,
    isInternetPermission,
    isAuthorityEnableDownloadMoyoProcessingOrder,
    isPartnersManagePermission,
    fallbackPath,
    goToFallbackPath,
  };
}
