import { Button } from '@mui/material';
import { FC } from 'react';
import useConfirm from '../../common/useConfirm';
import { partnersAdminUserControllerSerivce } from '../../new-api/partners-admin-user-controller-service';
import useAlert from '../../common/useAlert';
import { useLoading } from '../../context/LoadingContext';
import pbl from '../../pbl/pbl-service/pbl-service';

interface Props {
  userId: string;
}

const AccountPasswordResetButton: FC<Props> = ({ userId }) => {
  const comfirm = useConfirm();
  const alert = useAlert();
  const { setLoading } = useLoading();

  const resetPassword = async () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'reset-password',
        name: '비밀번호 초기화',
      },
    });
    const reset = await comfirm.open({
      title: '비밀번호 초기화',
      content: '정말 비밀번호를 초기화 하시겠습니까?',
      okText: '초기화',
      cancelText: '취소',
    });
    if (reset) {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'reset-password',
          name: '비밀번호 초기화',
        },
      });
      try {
        setLoading(true);
        const response =
          await partnersAdminUserControllerSerivce.resetUserPassword({
            body: {
              userId,
            },
          });
        setLoading(false);
        alert.open({
          title: '초기화 완료',
          content: `비밀번호가 초기화 되었습니다.\n새로운 비밀번호 : ${response.result?.newPassword}`,
          okText: '확인',
        });
      } catch (error) {
        alert.open({
          title: '초기화 실패',
          content: `비밀번호 초기화에 실패했습니다.`,
          okText: '확인',
        });
      } finally {
        setLoading(false);
      }
    } else {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'reset-password-cancel',
          name: '비밀번호 초기화 취소',
        },
      });
    }
  };

  return (
    <>
      <Button
        size="small"
        color="info"
        variant="contained"
        onClick={resetPassword}
      >
        비밀번호 초기화
      </Button>
    </>
  );
};

export default AccountPasswordResetButton;
