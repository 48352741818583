import { AbstractOasApiController } from './abstract-oas-api-controller';
import { UpdatePermissionRequest } from '../schema/update-permission-request';
import { ApiResponseAdminUserResponseV2 } from '../schema/api-response-admin-user-response-v2';
import { UpdatePasswordRequest } from '../schema/update-password-request';
import { ApiResponseBoolean } from '../schema/api-response-boolean';
import { UnlockAccountRequest } from '../schema/unlock-account-request';
import { ResetPasswordRequest } from '../schema/reset-password-request';
import { ApiResponseAdminUserPasswordResetResponse } from '../schema/api-response-admin-user-password-reset-response';
import { AdminOtpResetRequest } from '../schema/admin-otp-reset-request';
import { ApiResponseUnit } from '../schema/api-response-unit';
import { RegisterRequest } from '../schema/register-request';
import { ApiResponseRegisterMemberUserResponse } from '../schema/api-response-register-member-user-response';
import { AdminRegisterRequest } from '../schema/admin-register-request';
import { AuthenticationRequest } from '../schema/authentication-request';
import { ApiResponseUserTokenDto } from '../schema/api-response-user-token-dto';
import { ApiResponseAdminUserIssueOtpResponse } from '../schema/api-response-admin-user-issue-otp-response';
import { ApiResponseAdminUserListResponseV2 } from '../schema/api-response-admin-user-list-response-v2';
import { SseEmitter } from '../schema/sse-emitter';
export class PartnersAdminUserController extends AbstractOasApiController {
  /**
   * @path {put} - /api/partners-admin/users/update
   * @param body - request body 값
   */
  updatePermissions(data: { body: UpdatePermissionRequest }) {
    const path = '/api/partners-admin/users/update';
    const method = 'put';
    return this.api<ApiResponseAdminUserResponseV2>(
      path,
      method,
      undefined,
      data.body,
    );
  }
  /**
   * @path {put} - /api/partners-admin/users/password
   * @param body - request body 값
   */
  updatePassword(data: { body: UpdatePasswordRequest }) {
    const path = '/api/partners-admin/users/password';
    const method = 'put';
    return this.api<ApiResponseBoolean>(path, method, undefined, data.body);
  }
  /**
   * @path {post} - /api/partners-admin/users/unlock-account
   * @param body - request body 값
   */
  unlockAccount(data: { body: UnlockAccountRequest }) {
    const path = '/api/partners-admin/users/unlock-account';
    const method = 'post';
    return this.api<ApiResponseBoolean>(path, method, undefined, data.body);
  }
  /**
   * @path {post} - /api/partners-admin/users/reset-password
   * @param body - request body 값
   */
  resetUserPassword(data: { body: ResetPasswordRequest }) {
    const path = '/api/partners-admin/users/reset-password';
    const method = 'post';
    return this.api<ApiResponseAdminUserPasswordResetResponse>(
      path,
      method,
      undefined,
      data.body,
    );
  }
  /**
   * @path {post} - /api/partners-admin/users/reset-otp
   * @param body - request body 값
   */
  resetOtp(data: { body: AdminOtpResetRequest }) {
    const path = '/api/partners-admin/users/reset-otp';
    const method = 'post';
    return this.api<ApiResponseUnit>(path, method, undefined, data.body);
  }
  /**
   * @path {post} - /api/partners-admin/users/register
   * @param body - request body 값
   */
  registerMemberUser(data: { body: RegisterRequest }) {
    const path = '/api/partners-admin/users/register';
    const method = 'post';
    return this.api<ApiResponseRegisterMemberUserResponse>(
      path,
      method,
      undefined,
      data.body,
    );
  }
  /**
   * @path {post} - /api/partners-admin/users/register-admin
   * @param body - request body 값
   */
  registerAdminUser(data: { body: AdminRegisterRequest }) {
    const path = '/api/partners-admin/users/register-admin';
    const method = 'post';
    return this.api<ApiResponseRegisterMemberUserResponse>(
      path,
      method,
      undefined,
      data.body,
    );
  }
  /**
   * @path {post} - /api/partners-admin/users/login
   * @param body - request body 값
   */
  login(data: { body: AuthenticationRequest }) {
    const path = '/api/partners-admin/users/login';
    const method = 'post';
    return this.api<ApiResponseUserTokenDto>(
      path,
      method,
      undefined,
      data.body,
    );
  }
  /**
   * @path {post} - /api/partners-admin/users/issue-otp
   */
  issueOtp() {
    const path = '/api/partners-admin/users/issue-otp';
    const method = 'post';
    return this.api<ApiResponseAdminUserIssueOtpResponse>(path, method);
  }
  /**
   * @path {get} - /api/partners-admin/users
   */
  getAllCompanyMembers() {
    const path = '/api/partners-admin/users';
    const method = 'get';
    return this.api<ApiResponseAdminUserListResponseV2>(path, method);
  }
  /**
   * @path {get} - /api/partners-admin/users/session
   */
  connectSession() {
    const path = '/api/partners-admin/users/session';
    const method = 'get';
    return this.api<SseEmitter>(path, method);
  }
  /**
   * @path {get} - /api/partners-admin/users/me
   */
  getLoginUserInfo() {
    const path = '/api/partners-admin/users/me';
    const method = 'get';
    return this.api<ApiResponseAdminUserResponseV2>(path, method);
  }
  /**
   * @path {get} - /api/partners-admin/manage-admin/users
   */
  getAdminUsersForManageAdmin() {
    const path = '/api/partners-admin/manage-admin/users';
    const method = 'get';
    return this.api<ApiResponseAdminUserListResponseV2>(path, method);
  }
  /**
   * @path {delete} - /api/partners-admin/users/{removeTargetUserId}
   * @param paths - path에 치환되어 들어가는 값
   * @param paths.removeTargetUserId [string]
   */
  deleteMemberUser(data: {
    paths: {
      removeTargetUserId: string;
    };
  }) {
    const path = '/api/partners-admin/users/{removeTargetUserId}'.replace(
      '{removeTargetUserId}',
      encodeURIComponent(data.paths['removeTargetUserId']),
    );
    const method = 'delete';
    return this.api<ApiResponseBoolean>(path, method);
  }
}
