import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { getToken } from '../../common/cookie';

type AuthErrorHandlerProps = {
  children: JSX.Element;
};

type AuthErrorState = {
  authErrorMessage?: string;
};

export default function AuthErrorHandler({ children }: AuthErrorHandlerProps) {
  const location = useLocation();
  const state = location.state as AuthErrorState;
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  const hasNotToken = !getToken();
  const isUnauthorizedUserAttemptingAccess =
    hasNotToken &&
    location.pathname !== '/login' &&
    location.pathname !== '/change-password';

  useEffect(() => {
    if (isUnauthorizedUserAttemptingAccess || state?.authErrorMessage) {
      redirectLoginPage();
    }
  }, [state, isUnauthorizedUserAttemptingAccess]);

  const redirectLoginPage = () => {
    logoutUser();
    const redirectUrl =
      location.pathname === '/'
        ? ''
        : `?redirect-url=${encodeURIComponent(location.pathname)}`;

    state?.authErrorMessage && alert(state.authErrorMessage);
    navigate(`/login${redirectUrl}`);
  };

  if (isUnauthorizedUserAttemptingAccess || state?.authErrorMessage)
    return null;

  return children;
}
