import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { DataGrid, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { handleApi } from '../common/http_util';
import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useLoading } from '../context/LoadingContext';
import { InternetOrder, InternetOrderStatus } from '../entity/internet-order';
import { internetOrderApiService } from '../api/internet-order';
import InternetOrderSearchSection from '../components/OrderInternetListPage/InternetOrderSearchSection';
import { useInternetOptions } from '../context/InternetOptionsContext';
import { AxiosResponse } from 'axios';
import { InternetOrderTableColumns } from '../components/OrderInternetListPage/GridHeader';
import {
  pblResetSearchOrderFilter,
  pblSearchInternetOrderFilter,
} from '../pbl/pbl-common-service/pbl-search-order-filter';
import pbl from '../pbl/pbl-service/pbl-service';

const rows: Array<InternetOrder> = [];

export default function OrderInternetListPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoading();

  const [orders, setOrders] = useState<Array<InternetOrder>>(rows);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [dateStandard, setDateStandard] = useState<string>('createdAt');
  const [startDate, setStartDate] = useState<DateTime | undefined | null>(null);
  const [endDate, setEndDate] = useState<DateTime | undefined | null>(null);
  const [customerName, setCustomerName] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>('');
  const [planName, setPlanName] = useState<string>('');
  const [mno, setMno] = useState<string>('');
  const [status, setStatus] = useState<InternetOrderStatus | null>(null);

  const { statusList } = useInternetOptions();

  useEffect(() => {
    pbl('pageview', 'none', 'internet_orders');
  }, []);

  const getData = () => {
    const startDateParam = searchParams.get('startDate');
    let startDate;
    if (startDateParam != null) {
      startDate = DateTime.fromISO(startDateParam);
    }

    const endDateParam = searchParams.get('endDate');
    let endDate;
    if (endDateParam != null) {
      endDate = DateTime.fromISO(endDateParam);
    }

    return {
      customerName: searchParams.get('customerName'),
      customerPhoneNumber: searchParams
        .get('customerPhoneNumber')
        ?.replaceAll('-', ''),
      internetOrderName: searchParams.get('internetOrderName'),
      planName: searchParams.get('planName'),
      dateStandard: searchParams.get('dateStandard'),
      orderDateTimeFrom: startDate?.toISODate(),
      orderDateTimeTo: endDate?.toISODate(),
      status: searchParams.get('status'),
    };
  };

  const fetchOrders = useCallback(async () => {
    setLoading(true);

    const data = getData();

    let page = 0;
    const pageParams = parseInt(searchParams.get('page') ?? '');
    if (!isNaN(pageParams)) page = pageParams;

    let pageSize = 25;
    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) pageSize = pageSizeParams;

    const sortField = searchParams.get('sortField') ?? 'createdAt';
    const sortDirection = searchParams.get('sortDirection');
    let sort: 'asc' | 'desc' | null = 'desc';
    if (sortDirection === 'asc' || sortDirection === 'desc') {
      sort = sortDirection;
    }

    const orderList = await handleApi(
      () =>
        internetOrderApiService.getList(
          data,
          page,
          pageSize,
          sortField,
          sort ?? undefined,
        ),
      navigate,
    );

    setLoading(false);

    if (!orderList) return;

    setTotalCount(orderList.totalCount);
    setOrders(orderList.list);
  }, [searchParams]);

  useEffect(() => {
    fetchOrders();
  }, [searchParams]);

  useEffect(() => {
    const startDateParam = searchParams.get('startDate');
    if (startDateParam != null) setStartDate(DateTime.fromISO(startDateParam));

    const endDateParam = searchParams.get('endDate');
    if (endDateParam != null) setEndDate(DateTime.fromISO(endDateParam));

    setDateStandard(searchParams.get('dateStandard') ?? 'createdAt');
    setCustomerName(searchParams.get('customerName') ?? '');
    setCustomerPhoneNumber(
      searchParams.get('customerPhoneNumber')?.replaceAll('-', '') ?? '',
    );
    setPlanName(searchParams.get('planName') ?? '');
    setMno(searchParams.get('mno') ?? '');

    const status = searchParams.get('status');
    if (status) {
      const newStatus = statusList.find(
        (s: InternetOrderStatus) => s.key === status,
      );
      if (newStatus) {
        setStatus(newStatus);
      }
    }

    const pageParams = searchParams.get('page');
    const page = parseInt(pageParams ?? '');
    if (!isNaN(page)) {
      setPage(page);
    }

    const pageSizeParams = parseInt(searchParams.get('pageSize') ?? '');
    if (!isNaN(pageSizeParams)) {
      setPageSize(pageSizeParams);
    }

    const sortField = searchParams.get('sortField');
    const sortDirection = searchParams.get('sortDirection');
    if (sortField) {
      let sort: 'asc' | 'desc' | null = null;
      if (sortDirection === 'asc' || sortDirection === 'desc')
        sort = sortDirection;
      setSortModel([{ field: sortField, sort }]);
    }
  }, [searchParams, statusList]);

  const updateSearchParams = (data: {
    [key: string]: string | undefined | null;
  }) => {
    for (const [key, value] of Object.entries(data)) {
      if (value == null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }

    setSearchParams(searchParams);
  };

  const onClickSearch = () => {
    const searchData = {
      startDate: startDate?.toSQLDate(),
      endDate: endDate?.toSQLDate(),
      dateStandard,
      customerName,
      customerPhoneNumber,
      planName,
      mno,
      status: status?.key,
    };
    updateSearchParams(searchData);
    pblSearchInternetOrderFilter(searchData);
  };

  const onClickReset = () => {
    navigate(window.location.pathname);
    setDateStandard('createdAt');
    setStartDate(undefined);
    setEndDate(undefined);
    setCustomerName('');
    setCustomerPhoneNumber('');
    setPlanName('');
    setMno('');
    setStatus(null);
    pblResetSearchOrderFilter();
  };

  const onStartDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;

    setStartDate(date);
  };

  const onEndDateChange = (date: DateTime | null | undefined) => {
    if (date == null) return;
    setEndDate(date);
  };

  const onCustomerPhoneNumberChange = (phoneNumber: string) => {
    setCustomerPhoneNumber(phoneNumber.replace('-', ''));
  };

  const onPageChange = (page: number) => {
    updateSearchParams({ page: page.toString() });
  };

  const onPageSizeChange = (pageSize: number) => {
    updateSearchParams({ pageSize: pageSize.toString() });
  };

  const onSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length <= 0) return;

    updateSearchParams({
      sortField: newModel[0].field,
      sortDirection: newModel[0].sort?.toString(),
    });
  };

  const onClickExcel = async () => {
    setLoading(true);

    try {
      const res = await internetOrderApiService.exportToExcel(getData());

      setLoading(false);

      downloadFile(res);
    } catch (err) {
      console.error(err);
      alert('엑셀 파일 다운로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (file: AxiosResponse<any>) => {
    let blob = new Blob([file.data], {
      type: file.headers['content-type'],
    });
    let name = decodeURIComponent(file.headers['content-disposition']); //파일명 디코딩
    const fileName = getFileName(name);
    if (navigator && (navigator as any).msSaveOrOpenBlob) {
      // IE 10+는 해당 함수 제공
      (navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_self';
      if (fileName) link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href); //메모리 누수 방지
    }
  };

  const getFileName = (contentDisposition: string) => {
    let fileName = contentDisposition
      .split(';')
      .filter((el) => el.indexOf('filename') > -1)
      .map((ele) => ele.replace(/"/g, '').split('=')[1]);
    return fileName[0];
  };

  const onClickRow = (rowParams: GridRowParams) => {
    window.open(`/orders/internet/${rowParams.row.id}`, '_blank');
  };

  return (
    <>
      <Container sx={{ display: 'grid', mt: 3 }} maxWidth="xl">
        <Typography variant="h5" fontWeight={'bold'}>
          인터넷 접수
        </Typography>
        <InternetOrderSearchSection
          dateStandard={dateStandard}
          onDateStandardChanged={setDateStandard}
          startDate={startDate}
          onStartDateChanged={onStartDateChange}
          endDate={endDate}
          onEndDateChanged={onEndDateChange}
          customerName={customerName}
          onCustomerNameChanged={setCustomerName}
          customerPhoneNumber={customerPhoneNumber}
          onCustomerPhoneNumberChanged={onCustomerPhoneNumberChange}
          planName={planName}
          onPlanNameChanged={setPlanName}
          mno={mno}
          onMnoChanged={setMno}
          status={status}
          onStatusChanged={setStatus}
          statusList={statusList}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
            mb: 1,
          }}
        >
          <Button onClick={onClickSearch} variant="contained" sx={{ mr: 1 }}>
            검색
          </Button>
          <Button onClick={onClickReset} variant="outlined">
            검색 초기화
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" mt={3}>
            인터넷 상담 신청서
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            <Button onClick={onClickExcel}>엑셀로 추출하기</Button>
          </Box>
        </Box>
        <DataGrid
          sx={{
            mt: 3,
          }}
          rows={orders}
          columns={InternetOrderTableColumns}
          onRowClick={onClickRow}
          disableRowSelectionOnClick={true}
          autoHeight
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            onPageChange(newModel.page);
            onPageSizeChange(newModel.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
      </Container>
    </>
  );
}
