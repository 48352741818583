// create enum for role type
export enum Roles {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MEMBER = 'ROLE_MEMBER',
}

export type Role = `${Roles}`;

export enum Permissions {
  PARTNERS_EXCEL_UPLOAD_DOWNLOAD = 'PARTNERS_EXCEL_UPLOAD_DOWNLOAD', // 엑셀 다운로드 업로드 기능
  PARTNERS_INTERNET_ORDER = 'PARTNERS_INTERNET_ORDER', //인터넷 접수 조회 기능
  PARTNERS_MOBILE_PLAN_ORDER_MANAGE = 'PARTNERS_MOBILE_PLAN_ORDER_MANAGE', // 요금제 관리 기능
  PARTNERS_ORDER_CHANGE = 'PARTNERS_ORDER_CHANGE', // 요금제 접수 정보 수정 기능
  PARTNERS_ORDER_REGISTER = 'PARTNERS_ORDER_REGISTER', // 요금제 접수 조회 기능 (요금제 조회, 법인 요금제 접수, 전화상담 접수)
  PARTNERS_MANAGE_ADMIN = 'PARTNERS_MANAGE_ADMIN', // 파트너사 관리 권한
  PARTNERS_MOYO_COMPANY_MEMBER = 'PARTNERS_MOYO_COMPANY_MEMBER', // 모요 구성원 기본 권한
}

// NOTE: 새로 정의된 인증 인가 관련된 타입
export type Permission = `${Permissions}`;

export const hasRoleType = (roles: Role[], authRole: Role) => {
  return roles.some((role) => role === authRole);
};

export const hasPermissionType = (
  authorities: Permission[],
  authAuthority: Permission,
) => {
  return authorities.some((authority) => authority === authAuthority);
};
