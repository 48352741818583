import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { authApiService, UserMe } from '../../api/auth';
import { hasPermissionType, Permission } from '../../types/AuthType';

interface AuthSettingsModalProps {
  members: UserMe[];
  open: boolean;
  onClose: () => void;
  onDidChange: () => void;
}

const AuthSettingsModal: FC<AuthSettingsModalProps> = ({
  members,
  open,
  onClose,
  onDidChange,
}) => {
  const [hasRoleExcel, setHasRoleExcel] = useState<boolean>(false);
  const [hasRoleOrderEdit, setHasRoleOrderEdit] = useState<boolean>(false);
  const [hasRoleOrderRegister, setHasRoleOrderRegister] =
    useState<boolean>(false);
  const [hasRoleInternet, setHasRoleInternet] = useState<boolean>(false);
  const [hasRolePlanManage, setHasRolePlanManage] = useState<boolean>(false);
  const [hasAdminManage, setHasAdminManage] = useState<boolean>(false);

  useEffect(() => {
    if (members.length === 0) {
      setHasRoleExcel(false);
      setHasRoleOrderEdit(false);
      return;
    }
    setHasRoleExcel(
      hasPermissionType(
        members[0].permissions,
        'PARTNERS_EXCEL_UPLOAD_DOWNLOAD',
      ),
    );
    setHasRoleOrderEdit(
      hasPermissionType(members[0].permissions, 'PARTNERS_ORDER_CHANGE'),
    );
    setHasRoleOrderRegister(
      hasPermissionType(members[0].permissions, 'PARTNERS_ORDER_REGISTER'),
    );
    setHasRoleInternet(
      hasPermissionType(members[0].permissions, 'PARTNERS_INTERNET_ORDER'),
    );
    setHasRolePlanManage(
      hasPermissionType(
        members[0].permissions,
        'PARTNERS_MOBILE_PLAN_ORDER_MANAGE',
      ),
    );
    setHasAdminManage(
      hasPermissionType(members[0].permissions, 'PARTNERS_MANAGE_ADMIN'),
    );
  }, [members]);

  const updatedPermissions = (
    _permissions: Permission[],
    _hasRoleExcel: boolean,
    _hasRoleEditOrder: boolean,
    _hasRoleOrderRegister: boolean,
    _hasRoleInternet: boolean,
    _hasRolePlanManage: boolean,
    _hasAdminManage: boolean,
  ) => {
    const rolesSet = new Set(_permissions);
    _hasRoleExcel
      ? rolesSet.add('PARTNERS_EXCEL_UPLOAD_DOWNLOAD')
      : rolesSet.delete('PARTNERS_EXCEL_UPLOAD_DOWNLOAD');
    _hasRoleEditOrder
      ? rolesSet.add('PARTNERS_ORDER_CHANGE')
      : rolesSet.delete('PARTNERS_ORDER_CHANGE');
    _hasRoleOrderRegister
      ? rolesSet.add('PARTNERS_ORDER_REGISTER')
      : rolesSet.delete('PARTNERS_ORDER_REGISTER');
    _hasRoleInternet
      ? rolesSet.add('PARTNERS_INTERNET_ORDER')
      : rolesSet.delete('PARTNERS_INTERNET_ORDER');
    _hasRolePlanManage
      ? rolesSet.add('PARTNERS_MOBILE_PLAN_ORDER_MANAGE')
      : rolesSet.delete('PARTNERS_MOBILE_PLAN_ORDER_MANAGE');
    _hasAdminManage
      ? rolesSet.add('PARTNERS_MANAGE_ADMIN')
      : rolesSet.delete('PARTNERS_MANAGE_ADMIN');
    return Array.from(rolesSet);
  };

  const onChange = async () => {
    if (!window.confirm(`총 ${members.length}명의 권한을 수정하시겠습니까?`))
      return;
    try {
      const promiseList = members
        .map((member) => ({
          ...member,
          permissions: updatedPermissions(
            member.permissions,
            hasRoleExcel,
            hasRoleOrderEdit,
            hasRoleOrderRegister,
            hasRoleInternet,
            hasRolePlanManage,
            hasAdminManage,
          ),
        }))
        .map((member) =>
          authApiService.updatePermission(
            member.adminUserId,
            member.permissions,
          ),
        );
      await Promise.all(promiseList);
      onClose();
      onDidChange();
    } catch (_: any) {
      alert(`권한을 수정하는데 문제가 발생하였습니다. ${_.toString()}`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>일관 권한 변경</DialogTitle>
      <DialogContent>
        <DialogContentText>
          총 {members.length}명의 권한이 일관 변경됩니다.
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={hasRoleExcel}
                onChange={(e) => {
                  setHasRoleExcel(e.currentTarget.checked);
                }}
              />
            }
            label="엑셀 권한"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hasRoleOrderEdit}
                onChange={(e) => {
                  setHasRoleOrderEdit(e.currentTarget.checked);
                }}
              />
            }
            label="접수 내용 수정 권한"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hasRoleOrderRegister}
                onChange={(e) => {
                  setHasRoleOrderRegister(e.currentTarget.checked);
                }}
              />
            }
            label="접수 내용 조회 권한"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hasRoleInternet}
                onChange={(e) => {
                  setHasRoleInternet(e.currentTarget.checked);
                }}
              />
            }
            label="인터넷 권한"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hasRolePlanManage}
                onChange={(e) => {
                  setHasRolePlanManage(e.currentTarget.checked);
                }}
              />
            }
            label="요금제 관리 권한"
          />
          <FormControlLabel
            control={
              <Switch
                checked={hasAdminManage}
                onChange={(e) => {
                  setHasAdminManage(e.currentTarget.checked);
                }}
              />
            }
            label="파트너사 관리 권한"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          닫기
        </Button>
        <Button variant="contained" onClick={onChange}>
          변경하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthSettingsModal;
