import { Button } from '@mui/material';
import { FC } from 'react';
import useConfirm from '../../common/useConfirm';
import { partnersAdminUserControllerSerivce } from '../../new-api/partners-admin-user-controller-service';
import useAlert from '../../common/useAlert';
import { useLoading } from '../../context/LoadingContext';
import pbl from '../../pbl/pbl-service/pbl-service';

interface Props {
  userId: string;
}

const AccountUnlockButton: FC<Props> = ({ userId }) => {
  const comfirm = useConfirm();
  const alert = useAlert();
  const { setLoading } = useLoading();

  const unLockAccount = async () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'account-unlock',
        name: '잠금 해제',
      },
    });
    const reset = await comfirm.open({
      title: '잠금 해제',
      content:
        '비밀번호를 알고 계신 경우 사용해주세요.\n비밀번호를 잊으셨다면 ‘비밀번호 초기화’ 버튼을 눌러 새 비밀번호를 설정해주세요.',
      okText: '해제하기',
      cancelText: '취소',
    });
    if (reset) {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'account-unlock',
          name: '잠금 해제',
        },
      });
      try {
        setLoading(true);
        const response = await partnersAdminUserControllerSerivce.unlockAccount(
          {
            body: {
              userId,
            },
          },
        );
        setLoading(false);
        if (response.result) {
          alert.open({
            title: '해제 완료',
            content: `계정 잠금이 해제되었습니다.`,
            okText: '확인',
          });
        } else {
          throw new Error('계정 잠금 해제 실패');
        }
      } catch (error) {
        console.error(error);
        alert.open({
          title: '해제 실패',
          content: `계정 잠금 해제에 실패하였습니다.`,
          okText: '확인',
        });
      } finally {
        setLoading(false);
      }
    } else {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'account-unlock-cancel',
          name: '계정 잠금 해제 취소',
        },
      });
    }
  };

  return (
    <>
      <Button
        size="small"
        color="info"
        variant="contained"
        onClick={unLockAccount}
      >
        잠금 해제
      </Button>
    </>
  );
};

export default AccountUnlockButton;
