import { adminApi, moyoApi } from '../common/http_util';
import qs from 'query-string';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '../entity/api';
import {
  PagedPlanMetaAdmin,
  PlanEdit,
  PlanEditReserve,
  PlanHistory,
  PlanEditBulkData,
  PlanEditBulkResult,
  ScrapeReserve,
  SimplePlanMeta,
  ICreatePlanDto,
  IMobilePlan,
  PlanMetaLogHistory,
  IMobileOperatorMeta,
  PlanOperatorStatus,
} from '../entity/plan';
import { DateTime } from 'luxon';
import { PlanV2 } from '../entity/plan-v2';
import { MobilePlanOperatorId } from '../common/mobile-plan-operator-id';
import { PlanUnitPlanNoticeTemplateDto } from '../entity/planNotice';

/**
 * FIXME 토큰 방식을 사용하는 admin과 통합 필요
 */
class PlanApiService {
  getList(
    mvno: string,
    searchText?: string,
  ): Promise<AxiosResponse<ApiResponse<Array<SimplePlanMeta>>>> {
    return adminApi.get(`mobile-plan-meta/list`, {
      params: {
        mvno,
        q: searchText,
      },
    });
  }

  /**
   * TODO
   * @description 서버와 소통하여 파트너사가 이용할 때는 계정 토큰 기반으로 동작하게 리팩토링 필요
   */
  requestScraping(mobilePlanOperatorId?: number) {
    return adminApi.get(
      `mobile-plan-meta/scraping/mobilePlanOperatorId/${mobilePlanOperatorId}`,
      {},
    );
  }

  getPlansByMvnoPagination({
    mobilePlanOperatorId,
    searchText,
    searchIdText,
    isDeleted,
    state,
    mno,
    isCreatedOnMvnoStoreScraping,
    hasReservedUpdate,
    page,
    size,
    isManualPlan,
    display,
    isMoyoOnlyPlan,
    sortBy = 'id',
    sortOrder = 'desc',
  }: {
    mobilePlanOperatorId?: number;
    searchText: string;
    searchIdText?: string;
    isDeleted?: boolean;
    state?: string;
    mno?: string;
    isCreatedOnMvnoStoreScraping?: boolean;
    hasReservedUpdate?: boolean;
    page: number;
    size: number;
    isManualPlan?: boolean;
    display?: boolean;
    isMoyoOnlyPlan?: boolean;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
  }): Promise<AxiosResponse<ApiResponse<PagedPlanMetaAdmin>>> {
    const searchParams = new URLSearchParams();
    if (state && state !== 'ALL') {
      searchParams.set('state', state);
    }

    if (searchIdText !== undefined) {
      searchParams.set('id', searchIdText);
    }

    if (isDeleted) {
      searchParams.set('isDeleted', `${isDeleted}`);
    }

    if (display !== undefined) {
      searchParams.set('display', `${display}`);
    }

    if (mno) {
      searchParams.set('mno', mno);
    }

    if (isCreatedOnMvnoStoreScraping) {
      searchParams.set(
        'isCreatedOnMvnoStoreScraping',
        `${isCreatedOnMvnoStoreScraping}`,
      );
    }

    if (hasReservedUpdate) {
      searchParams.set('hasReservedUpdate', `${hasReservedUpdate}`);
    }

    if (isMoyoOnlyPlan != null) {
      searchParams.set('isMoyoOnlyPlan', `${isMoyoOnlyPlan}`);
    }

    if (isManualPlan !== undefined) {
      searchParams.set('isManual', `${isManualPlan}`);
    }

    if (mobilePlanOperatorId !== undefined) {
      searchParams.set('mobilePlanOperatorId', mobilePlanOperatorId.toString());
    }

    searchParams.set('q', searchText);
    searchParams.set('page', `${page}`);
    searchParams.set('size', `${size}`);
    searchParams.set('sort', `${sortBy},${sortOrder}`);

    const url = `mobile-plan-meta/v3/list?${searchParams.toString()}`;
    return adminApi.get(url);
  }

  /**
   * 요금제 부가 정보 조회
   * @param planId
   */
  getPlanDetail(planId: number): Promise<AxiosResponse<ApiResponse<PlanV2>>> {
    return adminApi.get(`mobile-plan-meta/detail/${planId}`);
  }

  /**
   * 요금제 수정 이력 목록 조회
   * @param planId
   */
  getPlanHistory(
    planId: number,
  ): Promise<AxiosResponse<ApiResponse<Array<PlanHistory>>>> {
    return adminApi.get(`mobile-plan-meta/${planId}/history`);
  }

  /**
   * 요금제 수정 이력 목록 조회 (로그 포함)
   * @param planId
   */
  getPlanMetaHistory(
    planId: number,
    page: number,
    size: number,
  ): Promise<AxiosResponse<ApiResponse<Array<PlanMetaLogHistory[]>>>> {
    return adminApi.get(`mobile-plan-meta/${planId}/change-log`, {
      params: {
        page,
        size,
      },
    });
  }

  /**
   * 요금제 변경 예약 내역 조회
   * @param planId
   */
  getPlanReserveEditHistoryById(
    planId: number,
  ): Promise<AxiosResponse<ApiResponse<Array<PlanEditReserve>>>> {
    return adminApi.get(`mobile-plan-meta/${planId}/reserve`);
  }

  /**
   * 요금제 예약 변경 내역 조회
   * @param reserveId
   * @returns
   */
  getPlanReservation(
    reserveId: number,
  ): Promise<AxiosResponse<ApiResponse<PlanEditReserve>>> {
    return adminApi.get(`mobile-plan-meta/reserve/${reserveId}`);
  }

  editPlanReservationBulk(
    data: PlanEditBulkData,
  ): Promise<AxiosResponse<ApiResponse<PlanEditBulkResult>>> {
    return adminApi.post('mobile-plan-meta/bulk-upsert/reserve-edit', data, {
      params: {
        addedByMvno: true,
      },
    });
  }

  deletePlanReserveEdit(reserveId: number) {
    return adminApi.delete(`mobile-plan-meta/reserve/${reserveId}`);
  }

  editPlanById(planId: number, data: PlanEdit) {
    return adminApi.put(`/mobile-plan-meta/${planId}`, data);
  }

  deletePlanCode(planId: number) {
    return adminApi.delete(`/mobile-plan-meta/${planId}/code`);
  }
  restorePlan(planId: number) {
    return adminApi.put(`/mobile-plan-meta/${planId}/restore`);
  }

  deletePlan(planId: number) {
    return adminApi.delete(`/mobile-plan-meta/${planId}`);
  }

  reserveEditPlan(planId: number, data: any) {
    return adminApi.put(`/mobile-plan-meta/${planId}/reserve-edit`, data);
  }

  reserveDeletePlan(planId: number, applyDate: DateTime) {
    const year = applyDate.year;
    const month = String(applyDate.month).padStart(2, '0');
    const date = String(applyDate.day).padStart(2, '0');
    const hour = String(applyDate.hour).padStart(2, '0');
    const minute = String(applyDate.minute).padStart(2, '0');
    return adminApi.post(`/mobile-plan-meta/${planId}/reserve-delete`, {
      applyDate: `${year}-${month}-${date}T${hour}:${minute}:00`,
    });
  }

  editPlanCommonInfo(mvno: string, mno: string, body: any) {
    return adminApi.put<ApiResponse<boolean>>(
      `/mobile-plan-meta/detail/mvno/${mvno}`,
      body,
      {
        params: {
          mno,
        },
      },
    );
  }

  createPlan(body: ICreatePlanDto) {
    return adminApi.post<ApiResponse<IMobilePlan>>(`/plan/create/v2`, body);
  }

  putPlan(planId: number, body: ICreatePlanDto) {
    return adminApi.put<ApiResponse<IMobilePlan>>(
      `/mobile-plan-meta/v2/${planId}`,
      body,
    );
  }

  putPlanUnitPlanNotices(data: { planId: number; templateIds: number[] }) {
    return adminApi.put<ApiResponse<IMobilePlan>>(
      `/plan-unit-plan-notices`,
      data,
    );
  }

  getPlanOperatorMeta(mobilePlanOperatorId: number) {
    return moyoApi.get<ApiResponse<IMobileOperatorMeta>>(
      `/mobile-plan-operator/${mobilePlanOperatorId}`,
    );
  }

  getPlanOperatorStatus(mobilePlanOperatorId: number) {
    return moyoApi.get<ApiResponse<PlanOperatorStatus>>(
      `/mobile-plan-operator/${mobilePlanOperatorId}/status`,
    );
  }

  // 요금제 단위 공지 리스트 조회
  getPlanUnitPlanNotices(mobilePlanOperatorId: MobilePlanOperatorId) {
    return moyoApi.get<ApiResponse<PlanUnitPlanNoticeTemplateDto[]>>(
      '/plan-unit-plan-notice-templates',
      {
        params: {
          mobilePlanOperatorId,
        },
      },
    );
  }
}

export const planApiService = new PlanApiService();
