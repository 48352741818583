import { useEffect, useState } from 'react';
import pbl from '../../pbl/pbl-service/pbl-service';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useLoading } from '../../context/LoadingContext';
import { AdminUserResponseV2 } from '../../openapi/auth/schema/admin-user-response-v2';
import AccountPasswordResetButton from './AccountPasswordResetButton';
import AccountResetButton from './AccountOtpResetButton';
import { ApiResponseAdminUserListResponseV2 } from '../../openapi/auth/schema/api-response-admin-user-list-response-v2';
import useAccountRegisterModal from './AccountRegister/useAccountRegisterModal';
import { Mvnos } from '../../entity/mvno';
import AccountUnlockButton from './AccountUnlockButton';
import { PartnerCompanyId } from '../../common/partner-company-id';

const columns: GridColDef<AdminUserResponseV2>[] = [
  { field: 'adminUserId', headerName: 'ID', flex: 1.5 },
  { field: 'name', headerName: '이름', flex: 1 },
  {
    field: 'partnerCompanyId',
    headerName: '통신사',
    flex: 1,
    renderCell: (data) => {
      const mvnoLabel =
        data.row.partnerCompanyId === PartnerCompanyId.모요
          ? '모요'
          : Mvnos.find(
              (mvno) => mvno.partnerCompanyId === data.row.partnerCompanyId,
            )?.label ?? '-';
      return <div>{mvnoLabel}</div>;
    },
  },
  {
    field: 'reset-password',
    headerName: '비밀번호 초기화',
    width: 120,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return <AccountPasswordResetButton userId={row.adminUserId} />;
    },
  },
  {
    field: 'reset-otp',
    headerName: 'OTP 초기화',
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return <AccountResetButton adminUserId={row.adminUserId} />;
    },
  },
  {
    field: 'unlock-account',
    headerName: '잠금 해제',
    width: 80,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return <AccountUnlockButton userId={row.adminUserId} />;
    },
  },
];

interface Props {
  title: string;
  getAllMembers: () => Promise<ApiResponseAdminUserListResponseV2>;
  registerUser?: (data: {
    userId: string;
    name: string;
    password: string;
    partnerCompanyId: PartnerCompanyId;
  }) => Promise<unknown>;
  hasPermission: boolean;
}

const RegisterAccountButton = ({
  registerUser,
  onComplete,
}: Required<Pick<Props, 'registerUser'>> & {
  onComplete: () => Promise<void> | void;
}) => {
  const accountRegisterModal = useAccountRegisterModal(registerUser);

  const onClickHandler = async () => {
    const result = await accountRegisterModal.open();
    if (result) {
      await onComplete();
    }
  };

  return (
    <Button variant="contained" onClick={onClickHandler}>
      계정 생성
    </Button>
  );
};

const CommonAccountManagePage = ({
  title,
  getAllMembers,
  registerUser,
  hasPermission,
}: Props) => {
  const [members, setMembers] = useState<AdminUserResponseV2[]>([]);
  const [pageSize, setPageSize] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const { setLoading } = useLoading();

  useEffect(() => {
    pbl('pageview', 'none', 'account_manage');
  }, []);

  useEffect(() => {
    if (hasPermission) {
      loadMembers();
    }
  }, []);

  const loadMembers = async () => {
    setLoading(true);
    try {
      const rs = await getAllMembers();
      setMembers(rs.result?.members || []);
    } finally {
      setLoading(false);
    }
  };

  if (!hasPermission) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" my={2}>
          잘못된 접근입니다.
        </Typography>
      </Box>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ minWidth: '760px', margin: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" my={2}>
            {title}
          </Typography>
          {registerUser && (
            <RegisterAccountButton
              registerUser={registerUser}
              onComplete={loadMembers}
            />
          )}
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
          paginationMode="client"
          autoHeight
          pagination
          pageSizeOptions={[30, 50, 100]}
          rows={members}
          columns={columns}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page);
            setPageSize(newModel.pageSize);
          }}
        />
      </div>
    </div>
  );
};

export default CommonAccountManagePage;
