import styled from '@emotion/styled';
import { Button, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { validatePassword } from '../../helpers/validatePassword';
import { authApiService } from '../../api/auth';

const Container = styled(Box)`
  height: 100vh;
  width: 100%;
  display: flex;
`;

type FormInputs = {
  id: string;
  username: string;
  password: string;
  confirmPassword: string;
};

/**
 * @김승태 (2025-03-10)
 * TODO: AccountRegister로 마이그레이션해서 공통으로 사용하도록 개선하기 + 계정 관리/생성/삭제 하나로 통합하기
 */
export const AccountCreatingForm = () => {
  const { handleSubmit, control, formState } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      await authApiService.createMember(data.username, data.password, data.id);
      alert(`${data.username}님의 계정이 생성되었습니다.`);
    } catch (error: any) {
      alert(error?.message);
    }
  };

  return (
    <Container>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '520px',
          margin: '0 auto',
        }}
      >
        <Box
          component="form"
          sx={{
            width: '100%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <img
            src="/moyo_partners.png"
            alt="모요 파트너스 로고"
            width={240}
            style={{ marginBottom: 8 }}
          />
          <Controller
            name="id"
            control={control}
            rules={{
              required: {
                value: true,
                message: '아이디를 입력해주세요.',
              },
            }}
            render={({ field }) => (
              <TextField
                error={!!formState.errors.id}
                helperText={formState.errors.id?.message}
                label="아이디*"
                type="text"
                margin="normal"
                fullWidth
                {...field}
              />
            )}
          />
          <Controller
            name="username"
            control={control}
            rules={{
              required: {
                value: true,
                message: '이름을 입력해주세요.',
              },
            }}
            render={({ field }) => (
              <TextField
                error={!!formState.errors.username}
                helperText={formState.errors.username?.message}
                label="이름*"
                type="text"
                margin="normal"
                fullWidth
                {...field}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{
              validate: (value) => {
                const { isValid, message } = validatePassword(value);

                return isValid || message;
              },
            }}
            render={({ field }) => (
              <TextField
                error={!!formState.errors.password}
                helperText={formState.errors.password?.message}
                label="비밀번호*"
                type="password"
                margin="normal"
                fullWidth
                {...field}
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              validate: (value, formValues) => {
                if (value !== formValues.password) {
                  return '비밀번호와 일치하지 않습니다.';
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                error={!!formState.errors.confirmPassword}
                helperText={formState.errors.confirmPassword?.message}
                label="비밀번호 확인*"
                type="password"
                margin="normal"
                fullWidth
                {...field}
              />
            )}
          />

          <Button
            sx={{ mt: 2 }}
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            생성
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};
