interface SelectOption {
  label: string;
  value: string;
}

const PHONE_MEMORY_MAP = {
  _64: { label: '64G', value: '64' },
  _128: { label: '128G', value: '128' },
  _256: { label: '256G', value: '256' },
  _512: { label: '512G', value: '512' },
  _1TB: { label: '1TB', value: '1024' },
};

const SAMSUNG_MODEL_OPTIONS = [
  { label: '갤럭시 S25', value: 'SM-S931' },
  { label: '갤럭시 S25 플러스', value: 'SM-S936' },
  { label: '갤럭시 S25 울트라', value: 'SM-S938' },
  { label: '갤럭시 Z Flip 6', value: 'SM-F741N' },
  { label: '갤럭시 Z Fold 6', value: 'SM-F956N' },
  { label: '갤럭시 Z Flip 5', value: 'SM-F731N' },
  { label: '갤럭시 Z Fold 5', value: 'SM-F946N' },
  { label: '갤럭시 Z Flip 4', value: 'SM-F721N' },
  { label: '갤럭시 Z Fold 4', value: 'SM-F936N' },
  { label: '갤럭시 S23', value: 'SM-S911N' },
  { label: '갤럭시 S23 플러스', value: 'SM-S916N' },
  { label: '갤럭시 S23 울트라', value: 'SM-S918N' },
  { label: '갤럭시 S24', value: 'SM-S921N' },
  { label: '갤럭시 S24 플러스', value: 'SM-S926N' },
  { label: '갤럭시 S24 울트라', value: 'SM-S928N' },
  { label: '갤럭시 A54', value: 'SM-A546' },
];

const SAMSUNG_MODEL_OPTIONS_MEMORY_MAP: { [key: string]: SelectOption[] } = {
  'SM-S931': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-S936': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-S938': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-F741N': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-F956N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-F731N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-F946N': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-F721N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-F936N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-S911N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-S916N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-S918N': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-S921N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-S926N': [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'SM-S928N': [
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  'SM-A546': [PHONE_MEMORY_MAP._256],
};

const IPHONE_MODEL_OPTIONS = [
  { label: 'iPhone16 Pro Max', value: 'A3296' },
  { label: 'iPhone16 Pro', value: 'A3293' },
  { label: 'iPhone16 Plus', value: 'A3290' },
  { label: 'iPhone16', value: 'A3287' },
  { label: 'iPhone15 Pro Max', value: 'A3106' },
  { label: 'iPhone15 Pro', value: 'A3102' },
  { label: 'iPhone15 Plus', value: 'A3094' },
  { label: 'iPhone15', value: 'A3089' },
  { label: 'iPhone14 Pro Max', value: 'A2894' },
  { label: 'iPhone14 Pro', value: 'A2890' },
  { label: 'iPhone14 Plus', value: 'A2886' },
  { label: 'iPhone14', value: 'A2882' },
  { label: 'iPhone 13 Pro Max', value: 'A2643' },
  { label: 'iPhone 13 Pro', value: 'A2638' },
  { label: 'iPhone 13', value: 'A2633' },
  { label: 'iPhone 13 mini', value: 'A2628' },
  { label: 'iPhone 12 Pro Max', value: 'A2411' },
  { label: 'iPhone 12 Pro', value: 'A2407' },
  { label: 'iPhone 12', value: 'A2403' },
  { label: 'iPhone 12 mini', value: 'A2399' },
  { label: 'iPhone SE 3세대', value: 'A2783' },
  { label: 'iPhone 11 Pro Max', value: 'A2218' },
  { label: 'iPhone 11 Pro', value: 'A2215' },
  { label: 'iPhone 11', value: 'A2221' },
  { label: 'iPhone SE 2세대', value: 'A2296' },
  { label: 'iPhone XR', value: 'A2105' },
  { label: 'iPhone XS Max', value: 'A2101' },
  { label: 'iPhone XS', value: 'A2097' },
];

const ETC_PHONE_MODEL_OPTIONS = [
  { label: 'Xiaomi 12 Pro', value: '2201122C' },
  { label: 'Pixel 3', value: 'G013A' },
];

const IPHONE_MODEL_OPTIONS_MEMORY_MAP: { [key: string]: SelectOption[] } = {
  A3296: [PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512, PHONE_MEMORY_MAP._1TB],
  A3293: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A3290: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A3287: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A3106: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],

  A3102: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A3094: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A3089: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A2894: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A2890: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A2886: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2882: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  'A2399-TG': [PHONE_MEMORY_MAP._128],
  A2783: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256],
  A2628: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2411: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2633: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2638: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A2643: [
    PHONE_MEMORY_MAP._128,
    PHONE_MEMORY_MAP._256,
    PHONE_MEMORY_MAP._512,
    PHONE_MEMORY_MAP._1TB,
  ],
  A2399: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256],
  A2407: [PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2403: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256],
  A2221: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256],
  A2218: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2215: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2296: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256],
  A2105: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._128, PHONE_MEMORY_MAP._256],
  A2101: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
  A2097: [PHONE_MEMORY_MAP._64, PHONE_MEMORY_MAP._256, PHONE_MEMORY_MAP._512],
};

export type { SelectOption };

export {
  PHONE_MEMORY_MAP,
  SAMSUNG_MODEL_OPTIONS,
  SAMSUNG_MODEL_OPTIONS_MEMORY_MAP,
  IPHONE_MODEL_OPTIONS,
  IPHONE_MODEL_OPTIONS_MEMORY_MAP,
  ETC_PHONE_MODEL_OPTIONS,
};
