import { useEffect, useState } from 'react';
import { Button, Container, Divider, Typography } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { planApiService } from '../api/plan';
import PlanDetailInfoComponent from '../components/PlanDetailPage/component/PlanDetailInfoComponent';
import PlanBasicInfoComponent from '../components/PlanDetailPage/component/PlanBasicInfoComponent';
import PlanHistoryComponent from '../components/PlanDetailPage/component/PlanHistoryComponent';
import PlanDetailHeader from '../components/PlanDetailPage/component/PlanDetailHeader';

import pbl from '../pbl/pbl-service/pbl-service';

import { Header, PageContainer } from '../components/PlanDetailPage/styled';
import AdditionalInfoComponent from '../components/PlanDetailPage/component/AdditionalInfoComponent';
import { usePlanMetaHistory } from '../hooks/usePlanMetaHistory';
import { useAuth } from '../context/UserContext';
import { useRole } from '../router/useRole';
import { env } from '../utils/env';
import { PlanV2 } from '../entity/plan-v2';
import Join from '../shared/join';
import PlanNotice from '../components/PlanDetailPage/component/PlanNotice';
import { createPlanDetailPageHelpers } from '../components/PlanDetailPage/logic/createPlanDetailPageHelpers';

interface PlanDetailPageProps {
  isMoyoOnly: boolean;
}

const PlanDetailPage = ({ isMoyoOnly }: PlanDetailPageProps) => {
  const { planId } = useParams();
  const { user } = useAuth();
  const { isMoyoRole } = useRole();
  const navigate = useNavigate();

  const [historyPage, setHistoryPage] = useState(0);
  const [historySize, setHistorySize] = useState(10);

  const [plan, setPlanDetail] = useState<PlanV2>();

  const { data: planHistoryRes, refetch: historyRefetch } = usePlanMetaHistory(
    Number(planId),
    historyPage,
    historySize,
  );

  const { getPlanDetailInfo } = createPlanDetailPageHelpers();
  const planDetailInfo = getPlanDetailInfo(isMoyoOnly, plan);

  const redirectIfNotOwnPlan = () => {
    alert('자사몰 요금제가 아닙니다. 리스트로 이동됩니다');
    navigate('/plans');
  };

  const handlePageChange = (newPage: number, newSize: number) => {
    setHistoryPage(newPage);
    setHistorySize(newSize);
  };

  useEffect(() => {
    if (
      !isMoyoRole &&
      user?.company?.mobilePlanOperatorId &&
      plan?.meta.mobilePlanOperatorId &&
      user.company.mobilePlanOperatorId !== plan.meta.mobilePlanOperatorId
    ) {
      redirectIfNotOwnPlan();
    }
  }, [user, plan, isMoyoRole, navigate]);

  useEffect(() => {
    if (!plan) return;
    const { adminPlanMeta, mobilePlanOperatorDto } = plan;
    pbl('pageview', 'none', 'plan_detail', {
      object: {
        id: planId,
      },
      eventProperties: {
        planId,
        adminPlanMeta,
        mobilePlanOperatorDto,
      },
    });
  }, [plan]);

  const fetchPlanDetail = async () => {
    let id = Number(planId);

    try {
      const [res] = await Promise.all([
        planApiService.getPlanDetail(id),
        //planApiService.getPlanHistory(id),
      ]);

      // 요금제 상세조회 권한관련된 로직 재구축 필요 hm.kim & jh.park - 2024.11.12
      // const response = await companyApiService.getInfo();
      // let mvnos = response.data.result?.plans;
      // let mvnoName = res.data.result?.meta.mvno;

      // if (
      //   mvnoName != null && // 모바일 운영사가 있는 경우
      //   res.data.result != null && // 요금제 상세 정보가 있는 경우
      //   mvnos?.includes(mvnoName) // 모바일 운영사가 있는 경우
      // ) {

      setPlanDetail(res.data.result);

      //setPlanHistoryList(historyRes.data.result);
      // } else {
      //   alert('해당 요금제 상세조회 권한이 없습니다');
      //   window.history.back();
      // }
    } catch (error) {
      console.log(error);
      redirectIfNotOwnPlan();
      throw error;
    }
  };

  useEffect(() => {
    fetchPlanDetail();
  }, []);

  const openMoyoPage = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'moyo_screen',
        name: '모요화면보기',
      },
    });
    window.open(`${env('REACT_APP_MOYO_WEB_URL')}/plans/${planId}`);
  };

  const refreshPlanDetail = () => {
    Promise.all([fetchPlanDetail(), historyRefetch()]);
  };

  if (
    !plan ||
    !user ||
    (plan?.meta.mobilePlanOperatorId !== user.company?.mobilePlanOperatorId &&
      !isMoyoRole)
  ) {
    return null;
  }

  const planUnitPlanNoticeList = plan.planNotices.planUnitPlanNoticeList.filter(
    (notice) => notice.id > 0,
  );

  return (
    <PageContainer>
      <Header>
        <Typography variant="h4">요금제 상세 정보</Typography>
        <Button variant={'text'} onClick={openMoyoPage}>
          모요 화면 보기
        </Button>
      </Header>
      <Container
        sx={{
          width: '860px',
          paddingBottom: '64px',
        }}
      >
        <PlanDetailHeader
          plan={plan}
          isMoyoOnly={isMoyoOnly}
          onRefresh={refreshPlanDetail}
        />

        {/* TODO - 예약 삭제 다이얼로그 */}
        {/* <ReserveDeleteConfirmDialog
          open={isOpen}
          onSuccess={(date: DateTime, isReservation: boolean) =>
            onDeleteReserve(date, isReservation)
          }
          onClose={() => setIsOpen(false)}
        /> */}

        <Join separator={<Divider sx={{ my: 6 }} />}>
          {/* 기본정보 */}
          <PlanBasicInfoComponent
            planMeta={plan.meta}
            onRefresh={refreshPlanDetail}
            adminPlanMeta={plan.adminPlanMeta}
          />

          {/* 요금정보 */}
          <PlanDetailInfoComponent
            planMeta={plan.meta}
            subTitle={planDetailInfo.subTitle}
            bottomContentArea={planDetailInfo.bottomContentArea}
          />

          {/* 부가정보 */}
          <AdditionalInfoComponent planMeta={plan.meta} />

          {/* 요금제 공지 */}
          {planUnitPlanNoticeList && (
            <PlanNotice planUnitPlanNoticeList={planUnitPlanNoticeList} />
          )}

          {/* 요금제 수정 내역 */}
          <PlanHistoryComponent
            page={historyPage}
            size={historySize}
            totalCount={planHistoryRes?.totalElements || 0}
            historyList={planHistoryRes?.content || []}
            onChangePage={handlePageChange}
          />
        </Join>
      </Container>
    </PageContainer>
  );
};

export default PlanDetailPage;
