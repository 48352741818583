export enum PartnerCompanyId {
  모요 = 1,
  KT스카이라이프 = 2,
  인스모바일 = 3,
  이지모바일 = 4,
  밸류컴 = 5,
  슈가모바일 = 6,
  아이즈모바일 = 7,
  마이월드 = 8,
  스마텔 = 9,
  유플러스인터넷 = 10,
  이야기모바일 = 11,
  테스트용 = 12,
  LG_U_PLUS = 13,
  에이모바일 = 14,
  모빙 = 15,
  LG_헬로비전 = 16,
  SK_브로드밴드 = 17,
  딜라이브 = 18,
  포인트플러스 = 19,
  마블링 = 20,
  btv = 21,
  KT = 22,
  SKT = 23,
  에르엘 = 24,
  사람과연결 = 25,
  위너스텔 = 26,
  쉐이크모바일 = 27,
  여유알뜰모바일 = 28,
  스노우맨 = 29,
  고고모바일 = 30,
  핀다이렉트 = 31,
  모나 = 32,
  티인포 = 33,
  오파스모바일 = 34,
  KG모바일 = 35,
  티플러스 = 36,
  // KG모바일 = 37, // 중복해서 있음 -> 근데 사용은 되지 않음
  한패스모바일 = 38,
  프리티 = 39,
  찬스모바일 = 40,
  리브엠 = 41,
  미디어로그 = 42,
  LG헬로모바일 = 43,
  시월모바일 = 44,
}
