import {
  Box,
  Container,
  Divider,
  GlobalStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApi } from '../common/http_util';
import { useLoading } from '../context/LoadingContext';
import { MobilePlanOrderHistory } from '../entity/order';
import InternetHistoryCreateSection from '../components/OrderInternetDetailPage/InternetHistoryCreateSection';
import { InternetOrder } from '../entity/internet-order';
import { internetOrderApiService } from '../api/internet-order';
import { VerticalColorTable } from '../shared/components/coloredTable';
import { dateUtils } from '../utils';
import pbl from '../pbl/pbl-service/pbl-service';

const globalStyles = (
  <GlobalStyles
    styles={{
      td: {
        ':nth-of-type(odd)': {
          backgroundColor: '#eeeeee',
          fontWeight: 600,
        },
      },
    }}
  />
);

const colGroupDefinition = (
  <colgroup>
    <col style={{ width: '15%' }} />
    <col style={{ width: '35%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '35%' }} />
  </colgroup>
);

export default function OrderInternetDetailPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const [order, setOrder] = useState<InternetOrder>();

  useEffect(() => {
    if (!orderId) return;
    pbl('pageview', 'none', 'internet_order_detail', {
      object: {
        id: orderId,
      },
      eventProperties: {
        orderId,
      },
    });
  }, []);

  const fetchOrder = async () => {
    setLoading(true);

    if (!orderId) return;

    try {
      const order = await handleApi(
        () => internetOrderApiService.getOrder(orderId),
        navigate,
      );

      if (!order) return;
      setOrder(order);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onHistorySubmit = () => {
    fetchOrder();

    if (window.opener) {
      window.opener.location.reload();
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  return (
    <>
      {globalStyles}
      <Container
        sx={{
          width: {
            xs: '100%',
            lg: '75%',
          },
          paddingBottom: '64px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" my={3}>
            온라인 가입 신청서
          </Typography>
          {/* TODO: 수정 페이지 기능을 일단 제거함. 기존 요금제 신청과 같은 페이지를 사용중 */}
        </Box>
        <Divider />

        <Typography variant="h6" my={2}>
          접수 정보
        </Typography>
        <TableContainer component={Paper} elevation={5}>
          <VerticalColorTable>
            {colGroupDefinition}
            <TableBody>
              <TableRow>
                <TableCell>신청자 이름</TableCell>
                <TableCell>{order?.customerName}</TableCell>
                <TableCell>접수 상태</TableCell>
                <TableCell>{order?.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>신청 일자</TableCell>
                <TableCell>
                  {dateUtils.dateToLocaleString(order?.createdAt)}
                </TableCell>
                <TableCell>신청 번호</TableCell>
                <TableCell colSpan={3}>{order?.customerPhoneNumber}</TableCell>
              </TableRow>
            </TableBody>
          </VerticalColorTable>
        </TableContainer>

        <Box
          my={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">상담 이력</Typography>
        </Box>

        {orderId && (
          <>
            <InternetHistoryCreateSection
              orderId={orderId}
              afterSubmitProcess={onHistorySubmit}
            />
          </>
        )}

        <TableContainer component={Paper} elevation={5} sx={{ mt: 3 }}>
          <Table
            sx={{
              td: {
                backgroundColor: 'transparent',
                fontWeight: 'normal',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>등록일</TableCell>
                <TableCell>상태</TableCell>
                <TableCell>상세 사유</TableCell>
                <TableCell>상담사</TableCell>
                <TableCell>상담 메모</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.historyList.map(
                (history: MobilePlanOrderHistory, index) => {
                  return (
                    <TableRow key={`history-${index}`}>
                      <TableCell>
                        {dateUtils.dateToLocaleString(history.createdAt)}
                      </TableCell>
                      <TableCell>{history.status}</TableCell>
                      <TableCell>{history.statusDetail}</TableCell>
                      <TableCell>{history.adminUserName}</TableCell>
                      <TableCell>{history.memo}</TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
