type ApiFp = <T>(
  path: string,
  method:
    | 'get'
    | 'put'
    | 'post'
    | 'delete'
    | 'options'
    | 'head'
    | 'patch'
    | 'trace',
  params?: any,
  data?: any,
) => Promise<T>;

export abstract class AbstractOasApiController {
  constructor(protected api: ApiFp) {}
}
