import { useOptions } from '../context/OptionsContext';
import { useAuth } from '../context/UserContext';
import { AdminUser } from '../entity/admin';
import { IMobileOperatorMeta } from '../entity/plan';
import { Permission } from '../types/AuthType';
import { PartnerCompanyId } from './partner-company-id';

const isCallActive = (adminUser?: AdminUser) => {
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('kTskylife') ||
    adminUser?.authorizedMvnos.includes('LGU') ||
    adminUser?.authorizedMvnos.includes('snowman')
  );
};

const isBaroActive = (adminUser?: AdminUser) => {
  return adminUser?.authorizedMvnos.includes('all');
};

/**
 * 개통 불가 알림톡 보내기 지원 Mvno 목록
 */
export const isSupportFailAlarmTalk = (adminUser?: AdminUser) => {
  // FIXME 서버 DB단으로 옮기기
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('ins') ||
    adminUser?.authorizedMvnos.includes('marvelring') ||
    adminUser?.authorizedMvnos.includes('egmobile') ||
    adminUser?.authorizedMvnos.includes('쉐이크모바일') ||
    adminUser?.authorizedMvnos.includes('sugarmobile')
  );
};

export const isMoyoAdmin = (adminUser?: AdminUser) =>
  adminUser?.authorizedMvnos?.includes('all') === true;

const isInternetBenefitConsult = (adminUser?: AdminUser) => {
  return (
    adminUser?.authorizedMvnos.includes('all') ||
    adminUser?.authorizedMvnos.includes('KT') ||
    adminUser?.partnerCompanyId === PartnerCompanyId.SK_브로드밴드
  );
};

const useMoyoActiveCollection = () => {
  const { user } = useAuth();
  const { adminUser } = useOptions();

  return {
    isSelfMoyoActive:
      user?.company?.isSelfActive ||
      user?.company?.id === PartnerCompanyId.핀다이렉트,
    isCallActive: isCallActive(adminUser),
    isBaroActive: isBaroActive(adminUser),
    isMoyoAdmin: isMoyoAdmin(adminUser),
    isInternetBenefitConsult: isInternetBenefitConsult(adminUser),
  };
};

export default useMoyoActiveCollection;
