import { type AxiosInstance } from 'axios';
import qs from 'query-string';

import { type AbstractOasApiController } from './abstract-oas-api-controller';

const SUPPORTED_METHOD_LIST = [
  'get',
  'delete',
  'put',
  'post',
  'patch',
] as const;

type SupportedMethodType = (typeof SUPPORTED_METHOD_LIST)[number];

function isSupportedMethod(method: string): method is SupportedMethodType {
  return SUPPORTED_METHOD_LIST.some((_method) => _method === method);
}

export async function instanceApiAdapter<T>(
  axiosInstance: AxiosInstance,
  ...args: Parameters<ConstructorParameters<typeof AbstractOasApiController>[0]>
): Promise<T> {
  const [path, method, params, data] = args;

  if (!isSupportedMethod(method)) {
    throw new Error('Method not supported');
  }

  const _path = path.startsWith('/') ? path.slice(1) : path;

  const response = await axiosInstance({
    method,
    url: _path,
    params,
    data,
    paramsSerializer: (params) => qs.stringify(params),
  });

  return response.data;
}
