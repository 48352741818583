import styled from '@emotion/styled';
import { Button, Grid, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { authApiService } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { validatePassword } from '../helpers/validatePassword';

const Container = styled(Box)`
  height: 100vh;
  display: flex;
`;

type FormInputs = {
  userId: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePasswordPage() {
  const { handleSubmit, control, formState } = useForm<FormInputs>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      await authApiService.changePassword(
        data.userId,
        data.currentPassword,
        data.newPassword,
      );
      alert('비밀번호가 성공적으로 변경되었습니다. 다시 로그인해주세요.');
      navigate('/login');
    } catch (error: any) {
      alert(error?.message);
    }
  };

  return (
    <Container>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={10} md={8} lg={6} xl={5}>
          <Paper
            component="form"
            elevation={5}
            sx={{ padding: '20px', display: 'flex', flexDirection: 'column' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <img
              src="/moyo_partners.png"
              alt="모요 파트너스 로고"
              width={240}
              style={{ marginBottom: 8 }}
            />
            <p>비밀번호를 변경해주세요. 비밀번호는 180일 이후 만료됩니다.</p>
            <Controller
              name="userId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '아이디를 입력해주세요.',
                },
              }}
              render={({ field }) => (
                <TextField
                  error={!!formState.errors.userId}
                  helperText={formState.errors.userId?.message}
                  label="아이디"
                  type="text"
                  margin="normal"
                  fullWidth
                  {...field}
                />
              )}
            />
            <Controller
              name="currentPassword"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: '기존 비밀번호를 입력해주세요.',
                },
              }}
              render={({ field }) => (
                <TextField
                  error={!!formState.errors.currentPassword}
                  helperText={formState.errors.currentPassword?.message}
                  label="기존 비밀번호"
                  type="password"
                  margin="normal"
                  fullWidth
                  {...field}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              rules={{
                validate: (value, formValues) => {
                  const { isValid, message } = validatePassword(value);

                  if (formValues.currentPassword === value) {
                    return '기존 비밀번호와 동일할 수 없습니다.';
                  }

                  return isValid || message;
                },
              }}
              render={({ field }) => (
                <TextField
                  error={!!formState.errors.newPassword}
                  helperText={formState.errors.newPassword?.message}
                  label="새 비밀번호"
                  type="password"
                  margin="normal"
                  fullWidth
                  {...field}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                validate: (value, formValues) => {
                  if (value !== formValues.newPassword) {
                    return '새 비밀번호와 일치하지 않습니다.';
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <TextField
                  error={!!formState.errors.confirmPassword}
                  helperText={formState.errors.confirmPassword?.message}
                  label="새 비밀번호 확인"
                  type="password"
                  margin="normal"
                  fullWidth
                  {...field}
                />
              )}
            />

            <Button
              sx={{ mt: 2 }}
              fullWidth
              variant="contained"
              size="large"
              type="submit"
            >
              변경
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
