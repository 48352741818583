import { useNavigate } from 'react-router-dom';
import { usePermission } from '../router/usePermission';
import { useEffect } from 'react';

export function EmptyPage() {
  const navigate = useNavigate();
  const { fallbackPath } = usePermission();

  useEffect(() => {
    if (fallbackPath !== '/') {
      navigate(fallbackPath);
    }
  }, [fallbackPath, navigate]);

  return <div></div>;
}
