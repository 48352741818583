import axios from 'axios';
import { env } from '../../utils/env';

export interface OpenApiClientOptions {
  service?: 'core' | 'auth';
}

export const createClient = (options?: OpenApiClientOptions) => {
  const { service = 'core' } = options ?? {};

  const client = axios.create({
    baseURL: `${env('REACT_APP_PROXY_API_BASE_URL')}/${service}`,
    withCredentials: true,
  });

  return client;
};
