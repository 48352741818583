import { FC, useEffect } from 'react';
import pbl from '../pbl/pbl-service/pbl-service';
import { SearchDataContextProvider } from '../components/SearchData/context/SearchDataContext';
import InternetConsultList from '../components/InternetConsult/InternetConsultList/InternetConsultListPage/InternetConsultList';

const InternetConsultListPage: FC = () => {
  useEffect(() => {
    pbl('pageview', 'none', 'internet_fixed_offers');
  }, []);

  return (
    <SearchDataContextProvider>
      <InternetConsultList />
    </SearchDataContextProvider>
  );
};

export default InternetConsultListPage;
