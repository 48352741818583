import { useEffect } from 'react';
import pbl from '../pbl/pbl-service/pbl-service';
import { useRole } from '../router/useRole';
import { partnersAdminUserControllerSerivce } from '../new-api/partners-admin-user-controller-service';
import CommonAccountManagePage from '../components/AccountManagePage/CommonAccountManagePage';

const PartnersAccountManagePage = () => {
  const { isAdminAccount } = useRole();

  useEffect(() => {
    pbl('pageview', 'none', 'account_manage');
  }, []);

  return (
    <CommonAccountManagePage
      getAllMembers={() => {
        return partnersAdminUserControllerSerivce.getAllCompanyMembers();
      }}
      hasPermission={isAdminAccount}
      title="계정 관리"
    />
  );
};

export default PartnersAccountManagePage;
