import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { OrderStatus } from '../../entity/order';
import { useRole } from '../../router/useRole';
import { Mvnos } from '../../entity/mvno';

type OrderSearchProps = {
  dateStandard?: string;
  onDateStandardChanged: (s: string) => void;
  startDate: DateTime | undefined | null;
  onStartDateChanged: (date: DateTime | undefined | null) => void;
  endDate: DateTime | undefined | null;
  onEndDateChanged: (date: DateTime | undefined | null) => void;
  customerName?: string;
  onCustomerNameChanged: (name: string) => void;
  customerPhoneNumber?: string;
  onCustomerPhoneNumberChanged: (number: string) => void;
  planName?: string;
  onPlanNameChanged: (name: string) => void;
  mno?: string;
  onMnoChanged: (name: string) => void;
  status: OrderStatus | null;
  onStatusChanged: (status: OrderStatus | null) => void;
  statusList: Array<OrderStatus>;
  registerType?: string;
  onRegisterTypeChanged?: (type: string) => void;
  hasUsim?: string;
  isEsim?: string;
  willPurchaseUsim?: string;
  onChange유심도착후_진행되지않은일자?: (duration?: string) => void;
  유심도착후_진행되지않은일자?: string;
  onHasUsimChanged?: (type: string) => void;
  mobilePlanOperatorId?: number;
  onMvnoChanged?: (type: number | undefined) => void;
  memo?: string;
  isChangePlanRequest?: boolean;
  onMemoChanged?: (memo?: string) => void;
  onSearch?: () => void;
  onIsChangePlanRequestChange?: (data: boolean | null) => void;
  birthday?: string;
  onBirthdayChanged?: (birthday: string) => void;
};

export default function OrderSearchSection({
  dateStandard,
  onDateStandardChanged,
  startDate,
  onStartDateChanged,
  endDate,
  onEndDateChanged,
  customerName,
  onCustomerNameChanged,
  customerPhoneNumber,
  onCustomerPhoneNumberChanged,
  planName,
  onPlanNameChanged,
  mno,
  onMnoChanged,
  status,
  onStatusChanged,
  statusList,
  registerType,
  onRegisterTypeChanged,
  hasUsim,
  isEsim,
  willPurchaseUsim,
  onChange유심도착후_진행되지않은일자,
  유심도착후_진행되지않은일자,
  onHasUsimChanged,
  memo,
  isChangePlanRequest,
  mobilePlanOperatorId,
  onMvnoChanged,
  onMemoChanged,
  onSearch,
  onIsChangePlanRequestChange,
  birthday,
  onBirthdayChanged,
}: OrderSearchProps) {
  const onStatusCheck = (value: OrderStatus | string) => {
    const newStatus = statusList.find((s: OrderStatus) => s.key === value);
    onStatusChanged(newStatus ?? null);
  };
  const { isMoyoRole } = useRole();

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (onSearch) onSearch();
  };

  const handleMvnoChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    onMvnoChanged?.(value ? Number(value) : undefined);
  };

  return (
    <Box>
      <form onSubmit={(e) => onSubmit(e)}>
        <button type="submit" hidden />
        <Grid container rowSpacing={2} columnSpacing={6} mt={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography mb={1}>날짜</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Select
                  size="small"
                  sx={{
                    marginRight: '1rem',
                  }}
                  defaultValue="createdAt"
                  value={dateStandard}
                  onChange={(e) => onDateStandardChanged(e.target.value)}
                >
                  <MenuItem value="createdAt">신청일</MenuItem>
                  <MenuItem value="completedAt">개통일</MenuItem>
                </Select>
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '1rem',
                  }}
                >
                  <DatePicker
                    label="시작일"
                    value={startDate}
                    onChange={(newValue) => {
                      onStartDateChanged(newValue);
                    }}
                    // datepicker with disableMaskedInput
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </div>
                <DatePicker
                  label="종료일"
                  value={endDate}
                  onChange={(newValue) => {
                    onEndDateChanged(newValue);
                  }}
                  // datepicker with disableMaskedInput
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography mb={1}>고객 이름</Typography>
            <TextField
              label="고객 성함"
              value={customerName}
              onChange={(e) => onCustomerNameChanged(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid xs={6} md={3} sx={{ padding: '16px 0 0 16px' }}>
            <Typography mb={1}>생년월일</Typography>
            <TextField
              label="생년월일"
              value={birthday}
              onChange={(e) => onBirthdayChanged?.(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>전화번호</Typography>
            <TextField
              label=" - 없이 전화번호를 입력해주세요 ( ex: 01012345678 )"
              value={customerPhoneNumber}
              onChange={(e) => onCustomerPhoneNumberChanged(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>요금제 이름</Typography>
            <TextField
              label="요금제 이름"
              value={planName}
              onChange={(e) => onPlanNameChanged(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>상태값</Typography>
            <Select
              fullWidth
              defaultValue="all"
              value={status?.key ?? 'all'}
              onChange={(e) => onStatusCheck(e.target.value)}
              size="small"
            >
              <MenuItem key={'all'} value="all">
                전체
              </MenuItem>
              {statusList.map((status) => (
                <MenuItem key={status.key} value={status.key}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>통신망</Typography>
            <Select
              fullWidth
              defaultValue=""
              value={mno}
              onChange={(e) => onMnoChanged(e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem key={'all'} value="">
                전체
              </MenuItem>
              <MenuItem key={'sk'} value="SKT">
                SKT망
              </MenuItem>
              <MenuItem key={'kt'} value="KT">
                KT망
              </MenuItem>
              <MenuItem key={'lg'} value="LGU">
                LG망
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>신청 유형</Typography>
            <Select
              fullWidth
              defaultValue=""
              value={registerType}
              onChange={(e) => onRegisterTypeChanged?.(e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem key={'all'} value="">
                전체
              </MenuItem>
              <MenuItem key={'change'} value="change">
                번호이동
              </MenuItem>
              <MenuItem key={'new'} value="new">
                신규가입
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>최신 메모</Typography>
            <TextField
              label="메모"
              value={memo}
              onChange={(e) => onMemoChanged?.(e.target.value)}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography mb={1}>유심 보유</Typography>
            <Select
              fullWidth
              defaultValue=""
              value={
                isEsim === 'true'
                  ? 'eSIM'
                  : willPurchaseUsim === 'true'
                  ? 'willPurchaseUsim'
                  : hasUsim
              }
              onChange={(e) => onHasUsimChanged?.(e.target.value)}
              size="small"
              displayEmpty
            >
              <MenuItem key={'all'} value="">
                전체
              </MenuItem>
              <MenuItem key={'true'} value="true">
                보유
              </MenuItem>
              <MenuItem key={'false'} value="false">
                미보유(배송)
              </MenuItem>
              <MenuItem key={'willPurchaseUsim'} value="willPurchaseUsim">
                미보유(직접구매)
              </MenuItem>
              <MenuItem key={'eSIM'} value="eSIM">
                eSIM
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography mb={1}>
              유심 배송 완료 후 N일 경과
              <Tooltip
                placement="top"
                title={
                  <>
                    유심 배송 완료 후 N일이 지나도
                    <br /> 개통을 진행하지 않고 있는고객을 검색할 수 있어요.
                  </>
                }
              >
                <HelpOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ verticalAlign: 'text-top', marginLeft: 0.5 }}
                />
              </Tooltip>
            </Typography>
            <Select
              fullWidth
              defaultValue=""
              value={유심도착후_진행되지않은일자 || ''}
              onChange={(e) =>
                onChange유심도착후_진행되지않은일자?.(
                  e.target.value ?? undefined,
                )
              }
              size="small"
              displayEmpty
            >
              <MenuItem key={''} value="">
                없음
              </MenuItem>
              <MenuItem key={2} value={2}>
                2일 이상
              </MenuItem>
              <MenuItem key={1} value={1}>
                1일 이상
              </MenuItem>
            </Select>
          </Grid>
          {isMoyoRole && (
            <Grid item xs={12} md={6}>
              <Typography mb={1}>통신사</Typography>
              <Select
                fullWidth
                defaultValue={''}
                value={mobilePlanOperatorId?.toString()}
                onChange={handleMvnoChange}
                size="small"
                displayEmpty
              >
                <MenuItem value="">전체</MenuItem>
                {Mvnos.map((key) => {
                  return (
                    <MenuItem key={key.operatorId} value={key.operatorId}>
                      {key.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
}
