import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { usePortal, usePortalController } from '../../../common/usePortal';
import { AccountRegister } from './AccountRegister';
import { useEffect, useState } from 'react';
import { ThemeControlProvider } from '../../../context/ThemeControlContext';
import CloseIcon from '@mui/icons-material/Close';
import { PartnerCompanyId } from '../../../common/partner-company-id';

interface AccountRegisterResult {
  userId: string;
  name: string;
  password: string;
  partnerCompanyId: PartnerCompanyId;
}

const AccountRegisterModal = ({
  onRegister,
}: {
  onRegister: (data: AccountRegisterResult) => Promise<unknown>;
}) => {
  const [open, setOpen] = useState(true);
  const { close, dismiss } = usePortalController<boolean>();

  useEffect(() => {
    if (!open) {
      setOpen(false);
    }
  }, [open]);

  const onRegisterHandler = async (data: AccountRegisterResult) => {
    try {
      await onRegister(data);
      alert(`${data.name}님의 계정이 생성되었습니다.`);
      close(true);
      setTimeout(() => {
        dismiss();
      }, 500);
    } catch (error: any) {
      alert(error?.message);
    }
  };
  const onClose = () => {
    close();
    dismiss();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">계정 생성하기</Typography>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <AccountRegister onRegister={onRegisterHandler} />
      </DialogContent>
    </Dialog>
  );
};

const useAccountRegisterModal = (
  register: (data: AccountRegisterResult) => Promise<unknown>,
) => {
  const portal = usePortal<boolean>();

  return {
    open: () => {
      return portal.open(
        <ThemeControlProvider>
          <AccountRegisterModal onRegister={register} />
        </ThemeControlProvider>,
      );
    },
  };
};

export default useAccountRegisterModal;
