import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { validatePassword } from '../../../helpers/validatePassword';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Mvnos } from '../../../entity/mvno';
import { PartnerCompanyId } from '../../../common/partner-company-id';

type FormInputs = {
  userId: string;
  name: string;
  partnerCompanyId: PartnerCompanyId;
  password: string;
  confirmPassword: string;
};

interface Props {
  onRegister: (data: Omit<FormInputs, 'confirmPassword'>) => void;
}

export const AccountRegister = ({ onRegister }: Props) => {
  const { handleSubmit, control, formState } = useForm<FormInputs>();
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      setLoading(true);
      await onRegister({
        userId: data.userId,
        name: data.name,
        password: data.password,
        partnerCompanyId: data.partnerCompanyId,
      });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        width: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="partnerCompanyId"
        control={control}
        rules={{
          required: {
            value: true,
            message: '통신사를 선택해주세요.',
          },
        }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="partner-company-id">통신사</InputLabel>
            <Select
              labelId="partner-company-id"
              fullWidth
              label="통신사"
              {...field}
            >
              {Mvnos.filter((mvno) => !!mvno.partnerCompanyId).map((mvno) => (
                <MenuItem value={mvno.partnerCompanyId}>{mvno.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="userId"
        control={control}
        rules={{
          required: {
            value: true,
            message: '아이디를 입력해주세요.',
          },
        }}
        render={({ field }) => (
          <TextField
            error={!!formState.errors.userId}
            helperText={formState.errors.userId?.message}
            label="아이디*"
            type="text"
            margin="normal"
            fullWidth
            {...field}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        rules={{
          required: {
            value: true,
            message: '이름을 입력해주세요.',
          },
        }}
        render={({ field }) => (
          <TextField
            error={!!formState.errors.name}
            helperText={formState.errors.name?.message}
            label="이름*"
            type="text"
            margin="normal"
            fullWidth
            {...field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={{
          validate: (value) => {
            const { isValid, message } = validatePassword(value);

            return isValid || message;
          },
        }}
        render={({ field }) => (
          <TextField
            error={!!formState.errors.password}
            helperText={formState.errors.password?.message}
            label="비밀번호*"
            type="password"
            margin="normal"
            fullWidth
            {...field}
          />
        )}
      />

      <Controller
        name="confirmPassword"
        control={control}
        rules={{
          validate: (value, formValues) => {
            if (value !== formValues.password) {
              return '비밀번호와 일치하지 않습니다.';
            }
            return true;
          },
        }}
        render={({ field }) => (
          <TextField
            error={!!formState.errors.confirmPassword}
            helperText={formState.errors.confirmPassword?.message}
            label="비밀번호 확인*"
            type="password"
            margin="normal"
            fullWidth
            {...field}
          />
        )}
      />

      <LoadingButton
        sx={{ mt: 2 }}
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        loading={loading}
      >
        생성
      </LoadingButton>
    </Box>
  );
};
